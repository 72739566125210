"use strict";

function initializeMenuBehavior() {
    var menuCollapse = document.getElementById('navbarMenuCollapse');
    if (menuCollapse) { // Check if menuCollapse is not null
        var bsCollapse;
        try {
            bsCollapse = new bootstrap.Collapse(menuCollapse, { toggle: false });
        } catch (error) {
            console.error('Failed to initialize bootstrap.Collapse:', error);
        }
        
        menuCollapse.addEventListener('shown.bs.collapse', () => document.body.classList.add('menu-visible'));
        menuCollapse.addEventListener('hidden.bs.collapse', () => document.body.classList.remove('menu-visible'));

        document.querySelectorAll('#navbarMenuCollapse a:not(.dropdown-toggle)').forEach(el => {
            el.addEventListener('click', () => bsCollapse.hide());
        });

        var navBg = document.querySelector('.nav-fullscreen-lg .nav-bg');
        if (navBg) { // Also check for navBg to avoid errors
            navBg.addEventListener('click', () => {
                bsCollapse.hide();
                return false;
            });
        }
    }
}

function handleHeaderVisibilityOnScroll() {
    var prevScrollpos = window.pageYOffset;
    var pageHeader = document.querySelector('.navbar-top');

    window.addEventListener("scroll", () => {
        var currScrollpos = window.pageYOffset;
        if (currScrollpos > prevScrollpos && currScrollpos > 64) {
            pageHeader.classList.add('hide-header');
        } else {
            pageHeader.classList.remove('hide-header');
        }
        prevScrollpos = window.pageYOffset;
        toggleBodyScrolledClass();
    });
}

function toggleBodyScrolledClass() {
    if (window.pageYOffset > 4) {
        document.body.classList.add('scrolled');
    } else {
        document.body.classList.remove('scrolled');
    }
}

function updateViewportHeight() {
    const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener("resize", setVh);
}

function initializeSwiperSliders() {
    const swiperSettings = {
        navigation: {
            nextEl: '.swiper-container.slider-simple-a .slider-next',
            prevEl: '.swiper-container.slider-simple-a .slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        loop: true,
        grabCursor: true,
        autoplay: {
            delay: 9000,
        },
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 600,
    };

    new Swiper('.slider-simple-a.swiper-container', swiperSettings);
    new Swiper('.slider-gallery-a.swiper-container', { ...swiperSettings, autoplay: { delay: 5000 }, effect: 'fade' });
    new Swiper('.slider-simple-b.swiper-container', { ...swiperSettings, navigation: {} });
}

function initializeScrollSliders() {
    try {
        new ScrollSlider('.scroll-simple-a', { scale1: 0.1 }).init();
        new SectionScroll('.sections-scroll', {
            sectionClass: 'section',
            navDotContainer: '.nav-dot-menu',
            changeOnSectionColor: '.change-on-section-color, .nav-dot-menu .nav-link'
        }).init();
    } catch (error) {
        console.log('ScrollSlider or SectionScroll not available');
    }
}

function initializeRellax() {
    try {
        new Rellax('.rellax', { breakpoints: [576, 768, 1201] });
    } catch (error) {
        console.log('Rellax not available');
    }
}

function initializeScrollAnim() {
    try {
        new ScrollAnim('.scroll-anim').init();
    } catch (error) {
        console.log('ScrollAnim not available');
    }
}

function hidePageLoader() {
    var pageLoader = document.querySelector('#page-loader');
    if (pageLoader) {
        pageLoader.classList.add('p-hidden');
    }
    document.body.classList.add('page-loaded');
}

window.addEventListener('load', function () {
    initializeMenuBehavior();
    handleHeaderVisibilityOnScroll();
    updateViewportHeight();
    initializeSwiperSliders();
    initializeScrollSliders();
    initializeRellax();
    initializeScrollAnim();
    hidePageLoader();
});
